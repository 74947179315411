









import Component from 'vue-class-component';
import Vue from 'vue';
import RsInterviewHeader from '@/modules/interview/components/main/header.vue';

@Component({
  metaInfo: {
    title: 'Interviews'
  },
  components: {RsInterviewHeader}
})
export default class Interview extends Vue {}
