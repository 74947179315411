














import Component from 'vue-class-component';
import Vue from 'vue';
import {DashboardRouter} from '@/modules/dashboard/router';
import RsLinkIcon from '@/shared/components/link/link-icon.vue';

@Component({
  name: 'rs-interview-header',
  components: {RsLinkIcon}
})
export default class DashboardHeader extends Vue {
  DashboardRouter = DashboardRouter;
}
